import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Typography, Divider, Button, Row, Col, Form, Input, Checkbox } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { isEmpty, getLangCode, formatDate, stringInsert, isDateValid, getMonth } from 'utils/helpers/helpers';
import Layout from 'components/layout/Layout';
import InfoGroup from 'components/infoGroup/InfoGroup';
import AppTable from 'components/table/AppTable';
import NumberEditPopup from 'components/input/NumberEditPopup';
import DayMonthEditPopup, { INVALID_DATE_INPUT } from 'components/input/DayMonthEditPopup';
import moment from 'moment';
import RoundImage from 'components/image/RoundImage';
import AppModal from 'components/modal/AppModal';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { actionSnackBar } from 'view/system/systemAction';
import { getMonthlyInventoryDetail, getMonthlyInventoryDetailV2, submitMonthInventory } from './InventoryDetailService';
import './InventoryDetail.scss';
import BorderlessAppSelect from 'components/select/borderlessAppSelect';
import { ReactComponent as FilterIcon } from 'assets/icons/ic_filter.svg';
import FilterModal from './FilterModal';

const { Title, Text } = Typography;
const { TextArea } = Input;

const EXPIRY_DATE_DISPLAY_FORMAT = 'DD / MM';
const EXPIRY_DATE_JSON_FORMAT = 'YYYY-MM-DD';
let PAGE_SIZE = 50;
let filter_product_names, filter_supplier_ids, filter_category_ids;
let wtfData = { id: -1, is_save: false, remark: "", items: [] };

const displayInputDate = (value) => {
  let displayValue;
  if (value instanceof Date || moment(value, EXPIRY_DATE_JSON_FORMAT, true).isValid()) {
    displayValue = formatDate(value, EXPIRY_DATE_DISPLAY_FORMAT);
  } else if (typeof value === 'string' && value.length > 2) {
    displayValue = stringInsert(value, 2, ' / ');
  } else {
    displayValue = value;
  }
  return displayValue || <span className="placeholder-date-format">{EXPIRY_DATE_DISPLAY_FORMAT}</span>;
};

const InventoryDetail = (props) => {
  const { formatMessage } = useIntl()
  const { id } = props.match.params;
  const [data, setData] = useState();
  const [loadingData, setLoadingData] = useState();
  const [mapItems, setMapItems] = useState({});
  const [confirmMode, setConfirmMode] = useState(false);
  const [inventoryIsSubmitted, setInventoryIsSubmitted] = useState(false)
  const [confirmSubmitModalVisible, setConfirmSubmitModalVisible] = useState(false);
  const [submitProgressModalVisible, setSubmitProgressModalVisible] = useState(false);
  const [formSubmitFailed, setFormSubmitFailed] = useState(false);
  const [formData] = Form.useForm();
  const [submitData, setSubmitData] = useState({
    remark: '',
    items: []
  });

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(0);
  const [displayPageSize, setDisplayPageSize] = useState(formatMessage({ id: 'IDS_APP_SELECT_DISPLAYING_ITEMS' }, { value: 50 }))

  const [filterOpen, setFilterOpen] = useState(false);
  let [filterValue, setFilterValue] = useState({});


  const updateWtfData = (item, fieldName, newValue) => {
    console.log('before', wtfData.items)
    const { quotation_id, product_id, expiry_date, remaining_qty } = item;
    const existingIndex = wtfData.items.findIndex(item => item.product_id === product_id)
    if (existingIndex === -1) {
      wtfData.items = [...wtfData.items, fieldName === 'expiry_date' ? {
        product_id: product_id,
        quotation_id: quotation_id,
        expiry_date: newValue,
        remaining_qty: remaining_qty
      } : {
        product_id: product_id,
        quotation_id: quotation_id,
        expiry_date: expiry_date,
        remaining_qty: newValue,
      }]
    } else {
      wtfData.items[existingIndex] = fieldName === 'expiry_date' ? {
        ...wtfData.items[existingIndex],
        expiry_date: newValue,
      } : {
        ...wtfData.items[existingIndex],
        remaining_qty: newValue,
      }
    }
    console.log(wtfData.items)
  }
  const toggleFilterModal = () => setFilterOpen(cur => !cur)

  const setPageSize = (value) => {
    setDisplayPageSize(value)
    PAGE_SIZE = value;
    if (page === 1) {
      refreshData(page)
    } else {
      setPage(1)
    }
  }

  const itemColumns = [
    {
      title: () => <div className='items-columns-container'><FormattedMessage id="IDS_ITEMS" />
        <div style={{ background: '#BDBDBD', borderRadius: '50%', height: 4, width: 4 }} />
        <BorderlessAppSelect
          optionLabelProp='title'
          selections={[{ id: 50, label: formatMessage({ id: 'IDS_APP_SELECT_ITEMS' }, { value: 50 }), title: formatMessage({ id: 'IDS_APP_SELECT_DISPLAYING_ITEMS' }, { value: 50 }) },
          { id: 100, label: formatMessage({ id: 'IDS_APP_SELECT_ITEMS' }, { value: 100 }), title: formatMessage({ id: 'IDS_APP_SELECT_DISPLAYING_ITEMS' }, { value: 100 }) }]}
          value={displayPageSize}
          onChange={setPageSize}
        />
      </div>,
      render: (item, actionProviders) => (
        <div className="app-flex-container item-info-container">
          <RoundImage src={item.image} alt="Item Image" />
          <div>
            <InfoGroup
              label={
                <>
                  {item.code}
                  <Divider type="vertical" />
                  <span style={{ color: 'red', fontSize: '17px' }}>
                    <FormattedMessage id="IDS_UNIT" values={{ value: item.unit }} />
                  </span>
                </>
              }
              labelClassName="item-info-label"
              noColon={true}
              className="item-info"
            >
              {item.name} {item.pack_weight}
            </InfoGroup>
          </div>
          {
            !actionProviders.isConfirmMode() && (
              <>
                <Form.Item name={['items', item._index, 'product_id']}
                  initialValue={item.product_id}
                  hidden>
                  <Input />
                </Form.Item>
                <Form.Item name={['items', item._index, 'quotation_id']}
                  initialValue={item.quotation_id}
                  hidden>
                  <Input />
                </Form.Item>
              </>
            )
          }
        </div>
      ),
    },
    {
      title: <FormattedMessage id="IDS_EXPIRY_DATE" />,
      key: 'expiryDate',
      align: 'center',
      width: '118px',
      render: (item, actionProviders) => {
        let expiryDate = actionProviders.getItemById(item.product_id)?.expiry_date;
        if (actionProviders.isConfirmMode()) {
          return <div className="input-cell expiry-date-input">
            {formatDate(expiryDate?.editValue, EXPIRY_DATE_DISPLAY_FORMAT)}
          </div>
        }
        return (
          <div className={`app-button input-cell expiry-date-input ${actionProviders.isFormSubmitFailed() && expiryDate?.error ? 'input-error' : ''}`}>
            <DayMonthEditPopup value={expiryDate?.editValue} year={actionProviders.getYear()}
              onCancel={() => actionProviders.editItemField(item.product_id, 'expiry_date', expiryDate?.originValue)}
              onPopupCancel={(originValue) => actionProviders.editItemField(item.product_id, 'expiry_date', originValue)}
              onValueChanged={(newValue) => actionProviders.editItemField(item.product_id, 'expiry_date', newValue)}
              onSubmit={(value) => actionProviders.handleEditItemFieldSubmit(item.product_id, item._index, 'expiry_date', formatDate(value, EXPIRY_DATE_JSON_FORMAT), item)}
              onError={(error) => actionProviders.handleExpiryDateInputError(item, error)}>
              <Button>
                {displayInputDate(expiryDate?.editValue)}
              </Button>
            </DayMonthEditPopup>
            <Form.Item name={['items', item._index, 'expiry_date']}
              initialValue={formatDate(expiryDate?.originValue, EXPIRY_DATE_JSON_FORMAT) || ''} hidden>
              <Input />
            </Form.Item>
          </div>
        );
      }
    },
    {
      title: <FormattedMessage id="IDS_REMAINING_QUANTITY" />,
      key: 'remainingQty',
      align: 'center',
      width: '111px',
      render: (item, actionProviders) => {
        let remainingQty = actionProviders.getItemById(item.product_id)?.remaining_qty;
        if (actionProviders.isConfirmMode()) {
          return <div className="input-cell remaining-quantity-input">
            {remainingQty?.editValue || 0}
          </div>;
        }
        return (
          <div className="app-button input-cell remaining-quantity-input">
            <NumberEditPopup expand value={remainingQty?.editValue} minValue={0} maxValue={99999} disableFractional={true}
              onCancel={() => actionProviders.editItemField(item.product_id, 'remaining_qty', 0)}
              onPopupCancel={(originValue) => actionProviders.editItemField(item.product_id, 'remaining_qty', originValue)}
              onValueChanged={(newValue) => actionProviders.editItemField(item.product_id, 'remaining_qty', newValue)}
              onSubmit={(value) => actionProviders.handleEditItemFieldSubmit(item.product_id, item._index, 'remaining_qty', value, item)}>
              <Button>
                {remainingQty?.editValue || 0}
              </Button>
            </NumberEditPopup>
            <Form.Item name={['items', item._index, 'remaining_qty']}
              initialValue={remainingQty?.originValue || 0} hidden>
              <Input />
            </Form.Item>
          </div>
        );
      }
    },
  ];

  const fetchData = async (inventoryId, page) => {
    try {
      //const res = await getMonthlyInventoryDetail(getLangCode(props.locale), inventoryId);
      const res = await getMonthlyInventoryDetailV2({
        lang_code: getLangCode(props.locale),
        id: inventoryId,
        page: page,
        number_of_items: PAGE_SIZE,
        filter_product_names,
        filter_supplier_ids,
        filter_category_ids
      });
      if (!isEmpty(res.data)) {
        return res.data;
      }
    } catch (e) {
      throw e;
    }
  };

  const initMapItems = (items) => {
    let result = {};
    items.forEach((item, index) => {
      if (index === 0) {
        console.log(item)
        // console.log(formData.getFieldsValue(true)?.items && formData.getFieldsValue(true).items[0])
        console.log('-----')
      }
      let expiryDate = moment(item.expiry_date, EXPIRY_DATE_JSON_FORMAT).toDate();
      expiryDate = isDateValid(expiryDate) ? expiryDate : undefined;

      const existingItemData = wtfData.items?.find(existingItem => {
        return existingItem.quotation_id === item.quotation_id && existingItem.product_id === item.product_id
      })

      result[item.product_id] = {
        ...item,
        expiry_date: {
          originValue: expiryDate,
          editValue: existingItemData?.expiry_date || expiryDate,
          error: !existingItemData?.expiry_date && !expiryDate
        },
        remaining_qty: {
          originValue: item.remaining_qty || 0,
          editValue: existingItemData?.remaining_qty || item.remaining_qty || 0
        }
      }
    });
    setMapItems(result);
  };

  const refreshData = async (page) => {
    setLoadingData(true);
    let response = await fetchData(id, page);
    let listInventories = response.data.inventory_list;
    setInventoryIsSubmitted(response.data.inventory_list?.status === 'submitted')
    setMaxPage(response.data.page.total);
    initMapItems(listInventories.items);
    setData(listInventories);
    setLoadingData(false);
  }

  useEffect(() => {
    return () => {
      filter_product_names = undefined;
      filter_supplier_ids = undefined;
      filter_category_ids = undefined;
      PAGE_SIZE = 50;
      wtfData = { id: -1, is_save: false, remark: "", items: [] }
    }
  }, [])

  useEffect(() => {
    refreshData(page);
  }, [page]);

  const isConfirmMode = () => {
    return confirmMode;
  };

  const isFormSubmitFailed = () => {
    return formSubmitFailed;
  };

  const getYear = () => {
    return data?.year;
  };

  const getItemById = (itemId) => {
    return mapItems[itemId];
  };

  const editItemField = (itemId, fieldName, newValue) => {
    let item = mapItems[itemId];
    if (item && item[fieldName]?.editValue !== newValue) {
      item[fieldName].editValue = newValue;
      item[fieldName].error = !newValue;
      setMapItems({ ...mapItems });
    }
  };

  const handleEditItemFieldSubmit = (product_id, itemIndex, fieldName, newValue, item) => {
    formData.setFields([
      {
        name: ['items', itemIndex, fieldName],
        value: newValue
      }
    ]);
    updateWtfData(item, fieldName, newValue)
    return true;
  };

  const handleFilter = (value) => {
    setFilterValue(value)
    const { suppliers, categories, items } = value;
    if (suppliers.length !== 0) {
      filter_supplier_ids = suppliers.reduce((accumulator, currentValue, currentIndex) => currentIndex === 0 ? `${currentValue.id}` : `${accumulator},${currentValue.id}`, '')
    } else {
      filter_category_ids = undefined
    }
    if (categories.length !== 0) {
      filter_category_ids = categories.reduce((accumulator, currentValue, currentIndex) => currentIndex === 0 ? `${currentValue.id}` : `${accumulator},${currentValue.id}`, '')
    } else {
      filter_category_ids = undefined
    }
    if (items.length !== 0) {
      filter_product_names = `["${items[0].name}"]`
    } else {
      filter_product_names = undefined
    }
    // formData.resetFields();
    if (page === 1) {
      refreshData(page)
    } else {
      setPage(1)
    }
  }

  const handleExpiryDateInputError = (item, error) => {
    switch (error) {
      case INVALID_DATE_INPUT: {
        props.actionSnackBar({
          open: true,
          type: 'error',
          messageID: 'IDS_INVALID_DATE_INPUT_FORMAT',
        });
        break;
      }
      default:
        break
    }
  };

  const renderBodyGroup = () => {
    let table = <AppTable inInventoryPage columns={itemColumns}
      dataSource={data?.items}
      showLoading={loadingData}
      actionProviders={{
        getItemById: getItemById,
        editItemField: editItemField,
        getYear: getYear,
        handleExpiryDateInputError: handleExpiryDateInputError,
        handleEditItemFieldSubmit: handleEditItemFieldSubmit,
        isConfirmMode: isConfirmMode,
        isFormSubmitFailed: isFormSubmitFailed
      }}
      renderBottom={renderRemarkInput}
      pagination={{
        page: page - 1,
        size: PAGE_SIZE,
        totalItems: maxPage * PAGE_SIZE
      }}
      onPageChange={(page) => setPage(page)}
    />;
    if (confirmMode) {
      return table;
    }
    return (
      <Form form={formData} scrollToFirstError={true}
        onFinish={showConfirmInventory} onFinishFailed={() => setFormSubmitFailed(true)}>
        <Form.Item name={['id']} initialValue={Number(id)} hidden>
          <Input />
        </Form.Item>
        <Form.Item name='is_save' initialValue={false} valuePropName="checked" hidden>
          <Checkbox />
        </Form.Item>
        {table}
      </Form>
    );
  };

  const renderRemarkInput = () => {
    let remark;
    if (confirmMode) {
      remark = submitData.remark;
    } else {
      remark = <Form.Item name="remark" initialValue={submitData.remark}>
        <TextArea />
      </Form.Item>
    }
    return (
      <Row className="remark-input-container">
        <Col span={24}>
          <div className="remark-input-label">
            <FormattedMessage id="IDS_REMARK" />
          </div>
          <div className="remark-input">
            {remark}
          </div>
        </Col>
      </Row>
    )
  };

  const goBack = () => {
    props.history.goBack();
  };

  const backToEdit = () => {
    setConfirmMode(false);
  };

  const showConfirmInventory = (submitData) => {
    setSubmitData(submitData);
    if (!submitData.is_save) {
      setConfirmMode(true);
    }
  };

  const confirmSubmitInventory = () => {
    setConfirmSubmitModalVisible(true);
  };

  const submitInventory = () => {
    setSubmitProgressModalVisible(true);
    setConfirmSubmitModalVisible(false);

    submitMonthInventory({ ...wtfData, is_save: false, remark: submitData.remark, id: Number(id) })
      .then(() => {
        setSubmitProgressModalVisible(false);
        goBack();
        props.actionSnackBar({
          open: true,
          type: 'success',
          // messageID: 'IDS_INVENTORY_SUBMIT_SUCCESS_MESSAGE',
          message: <span style={{ fontSize: 20 }}><FormattedMessage id='IDS_INVENTORY_SUBMIT_SUCCESS_MESSAGE' /></span>
        });
      });
  };

  const handleSave = () => {
    formData.setFieldsValue({ is_save: true })
    formData.submit();
    console.log(formData.getFieldsValue(true))
    submitMonthInventory({ ...wtfData, is_save: true, remark: formData.getFieldsValue(true).remark, id: Number(id) })
      .then(() => {
        props.actionSnackBar({
          open: true,
          type: 'success',
          // messageID: 'IDS_INVENTORY_SAVE_SUCCESS_MESSAGE',
          message: <span style={{ fontSize: 20 }}><FormattedMessage id='IDS_INVENTORY_SAVE_SUCCESS_MESSAGE' /></span>
        });
      });
  }

  return (
    <div className="inventory-detail-container">
      <Layout emptyDrawer={true}>
        <div className="app-scrollable-container">
          <div className="app-content-container">
            <div className="header-group">
              <div className="page-info-container">
                <div className="page-title">
                  <Title level={3}>
                    <FormattedMessage id="IDS_INVENTORY" />
                    {
                      data && <>
                        &nbsp;- <FormattedMessage id={getMonth(data?.month)} /> {data?.year}
                      </>
                    }
                  </Title>
                  <Text>
                    <FormattedMessage id="IDS_STORE_NAME" values={{ name: props.account?.store?.company_name }} />
                  </Text>
                </div>
                <Button
                  className={`${!isEmpty(filterValue.suppliers) ||
                    !isEmpty(filterValue.categories) ||
                    !isEmpty(filterValue.items)
                    ? 'active-btn'
                    : ''
                    }`}
                  icon={<FilterIcon />}
                  onClick={toggleFilterModal}
                >
                  <FormattedMessage id="IDS_FILTER" />
                </Button>
              </div>
            </div>
            <div className="body-group">
              {renderBodyGroup()}
            </div>
            <div className="footer-group app-button">
              <Button className="back-button" onClick={() => confirmMode ? backToEdit() : goBack()}>
                <FormattedMessage id={confirmMode ? 'IDS_BACK_TO_EDIT' : 'IDS_BACK'} />
              </Button>
              {!confirmMode && <Button disabled={loadingData || inventoryIsSubmitted} type="primary" className="save-button"
                onClick={handleSave}>
                <FormattedMessage id={'IDS_SAVE'} />
              </Button>}
              <Button
                disabled={loadingData || inventoryIsSubmitted}
                type="primary" className="submit-button"
                onClick={() => {
                  if (confirmMode) {
                    confirmSubmitInventory()
                  } else {
                    formData.setFieldsValue({ is_save: false })
                    formData.submit()
                  }
                }}>
                <FormattedMessage id={confirmMode ? 'IDS_CONFIRM' : 'IDS_SUBMIT'} />
              </Button>
            </div>
          </div>
        </div>
        <FilterModal
          handleClose={toggleFilterModal}
          openModal={filterOpen}
          inventoryId={id}
          filterValue={filterValue}
          handleFilter={handleFilter}
        />
        <AppModal className="inventory-detail-modal"
          visible={confirmSubmitModalVisible}
          onVisibleChange={(visible) => setConfirmSubmitModalVisible(visible)}
          titleID="IDS_SUBMIT_INVENTORY_FORM"
          okTextID="IDS_SUBMIT" onOk={submitInventory}>
          <div className="modal-message">
            <FormattedMessage id="IDS_SUBMIT_INVENTORY_FORM_MESSAGE" />
          </div>
        </AppModal>
        <AppModal className="inventory-detail-modal"
          visible={submitProgressModalVisible}
          onVisibleChange={(visible) => setSubmitProgressModalVisible(visible)}
          titleID="IDS_SUBMIT_INVENTORY_FORM" hideOkButton={true} hideCancelButton={true}>
          <div className="modal-progress-container">
            <CircularProgress />
          </div>
        </AppModal>
      </Layout>
    </div>
  )
};

export default connect(
  state => ({
    locale: state.system.locale,
    account: state.system.account
  }),
  { actionSnackBar }
)(withRouter(InventoryDetail));
