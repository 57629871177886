import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Modal } from 'antd';
import { withStyles } from '@material-ui/core/styles';
import { Radio, FormControlLabel } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as icons from 'assets';
import { isEmpty, removeDuplicateName } from 'utils/helpers/helpers';
import SelectCustom from 'components/select/SelectCustom';
import { BootstrapInput } from 'components/select/BootstrapInput';
import { getCategoryNames, getCategorySuppliers } from '../orderDetails/CategoryOrderService';
import { getLangCode } from '../../../utils/helpers/helpers';

const CustomRadio = withStyles({
  root: {
    color: '#6461B4',
    '&$checked': {
      color: '#6461B4',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const useStyles = makeStyles({
  noOptions: {
    color: '#4F4E66',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '24px',
  },
});

const FilterModal = (props) => {
  const { locale } = props;
  const intl = useIntl();
  const styles = useStyles();
  const { handleClose, visible, handleFilter, categoryId, isFavCategory } = props;

  const [names, setNames] = useState();
  const [suppliers, setSuppliers] = useState();

  const [selectedNames, setSelectedNames] = useState({});
  const [selectedSuppliersArr, setSelectedSuppliersArr] = useState([]);
  const [filled, setFilled] = useState();

  useEffect(async () => {
    const res = await getCategorySuppliers({
      lang_code: getLangCode(locale),
      id: categoryId,
      is_favorite_category: isFavCategory
    });
    if (!suppliers){
      setSuppliers(res?.data?.data?.suppliers);
    }
  }, [categoryId, isFavCategory, suppliers]);

  useEffect(async () => {
    const res = await getCategoryNames({
      lang_code: getLangCode(locale),
      category_ids: categoryId,
      is_favorite_category: isFavCategory,
      supplier_ids: Object.keys(selectedSuppliersArr).join(',')
    });
    if (!names){
      setNames(res?.data?.data?.products?.map((item) => item.name));
      setSelectedNames({});
    }
  }, [categoryId, isFavCategory, selectedSuppliersArr, names]);

  const toggleNameSelectionState = (name) => {
    if (selectedNames[name]) {
      const newSelectedNames = {...selectedNames};
      delete newSelectedNames[name];
      setSelectedNames(newSelectedNames);
    } else {
      setSelectedNames({
        ...selectedNames,
        [name]: true
      });
    }
  };

  const toggleSupplierSelectionState = (supplier) => {
    const index = selectedSuppliersArr.findIndex((selectedSupplier) => selectedSupplier.id === supplier.id);
    const newSelectedSuppliersArr = [...selectedSuppliersArr];
    if (index < 0) {
      newSelectedSuppliersArr.push(supplier);
    } else {
      newSelectedSuppliersArr.splice(index, 1);
    }
    setSelectedSuppliersArr(newSelectedSuppliersArr)
  };

  const handleReset = () => {
    setSelectedNames({});
    setSelectedSuppliersArr([]);
    setFilled(undefined);
  };

  const handleSave = () => {
    if (handleFilter) {
      handleFilter({
        isFilled: filled,
        productNames: Object.keys(selectedNames),
        supplierIds: selectedSuppliersArr.map((supplier) => supplier.id)
      });
    }
    handleClose();
  };

  return (
    <Modal
      visible={visible}
      title={null}
      centered
      closeIcon={<img src={icons.ic_close} alt="" />}
      onOk={handleClose}
      onCancel={handleClose}
      className="modal-container"
      width={476}
      footer={null}
    >
      <div className="modal-filter-content">
        <p className="title-filter-text">
          <FormattedMessage id="IDS_SAVE_ORDER_ITEM" />
        </p>
        <p className="title-item">
          <FormattedMessage id="IDS_ITEMS" />
        </p>
        <Autocomplete
          id="combo-box-demo"
          classes={{ noOptions: styles.noOptions }}
          noOptionsText={intl.formatMessage({ id: 'IDS_NO_KEYWORD_MATCH' })}
          options={names}
          style={{ width: '100%' }}
          renderInput={(params) => {
            const { InputProps, ...rest } = params;
            return (
              <BootstrapInput
                {...InputProps}
                {...rest}
                placeholder={intl.formatMessage({
                  id: 'IDS_PLACEHOLDER_SEARCH_ITEMS',
                })}
                startAdornment={
                  <img src={icons.ic_search_black} alt="" style={{ margin: '0 8px 0 16px', cursor: 'pointer' }}/>
                }
                endAdornment={null}
              />
            );
          }}
          renderOption={(option) => (
            <p
              key={option}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
              onClick={() => toggleNameSelectionState(option)}
            >
              <span>{option}</span>
              <CustomRadio
                checked={selectedNames[option]}
              />
            </p>
          )}
        />

        <div className="tag-group">
          {
            Object.keys(selectedNames).map((selectedName, index) => (
              <span className="tag-item" key={index}>
                {`${selectedName} `}
                <img src={icons.ic_close} alt="" onClick={() => toggleNameSelectionState(selectedName)}/>
              </span>
            ))
          }
        </div>

        <p className="title-item">
          <FormattedMessage id="IDS_SUPPLIER" />
        </p>
        <Autocomplete
          id="combo-box-demo"
          classes={{ noOptions: styles.noOptions }}
          noOptionsText={intl.formatMessage({ id: 'IDS_NO_KEYWORD_MATCH' })}
          options={suppliers}
          getOptionLabel={option => option.name}
          style={{ width: '100%' }}
          renderInput={(params) => {
            const { InputProps, ...rest } = params;
            return (
              <BootstrapInput
                {...InputProps}
                {...rest}
                placeholder={intl.formatMessage({
                  id: 'IDS_PLACEHOLDER_SEARCH_SUPPLIERS',
                })}
                startAdornment={
                  <img src={icons.ic_search_black} alt="" style={{ margin: '0 8px 0 16px', cursor: 'pointer' }}/>
                }
                endAdornment={null}
              />
            );
          }}
          renderOption={(option) => (
            <p
              key={option.id}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
              onClick={() => toggleSupplierSelectionState(option)}
            >
              <span>{option.name}</span>
              <CustomRadio
                checked={selectedSuppliersArr[option.id]}
              />
            </p>
          )}
        />

        <div className="tag-group">
          {
            selectedSuppliersArr.map((supplier, index) => (
              <span className="tag-item" key={index}>
                {`${supplier.name} `}
                <img src={icons.ic_close} alt=""
                     onClick={() => toggleSupplierSelectionState(supplier)}
                />
              </span>
            ))}
        </div>

        <p className="title-item">
          <FormattedMessage id="IDS_STATUS" />
        </p>
        <FormControlLabel
          value={0}
          control={<CustomRadio checked={filled === 1} />}
          style={{ width: 'calc(50% - 8px)' }}
          label={
            <span className="status-group">
              <FormattedMessage id="IDS_FILLED" />
            </span>
          }
          onClick={() => setFilled(filled === 1 ? null : 1)}
        />
        <FormControlLabel
          value={1}
          control={<CustomRadio checked={filled === 0} />}
          style={{ width: 'calc(50% - 8px)' }}
          label={
            <span className="status-group">
              <FormattedMessage id="IDS_NOT_FILLED" />
            </span>
          }
          onClick={() => setFilled(filled === 0 ? null : 0)}
        />
        <div className="filter-footer">
          <Button className="outline-btn" onClick={handleReset}>
            <FormattedMessage id="IDS_RESET" />
          </Button>
          <Button className="primary-btn" onClick={handleSave}>
            <FormattedMessage id="IDS_DONE" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default FilterModal;
