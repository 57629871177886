import React, { useState, useEffect, forwardRef } from 'react';
import { usePageCache } from 'components/hook/AppHook';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col, Space, Radio, Card, Typography, Tag, Divider } from 'antd';
import Layout from 'components/layout/Layout';
import StatusTag from 'components/statusTag/StatusTag';
import InfoGroup from 'components/infoGroup/InfoGroup';
import AppList from 'components/list/AppList';
import { FormattedMessage } from 'react-intl';
import { formatDate, isEmpty, getLangCode } from 'utils/helpers/helpers';
import { routes, STATUS_PENDING, STATUS_RECEIVED, STATUS_SUBMITTED } from 'utils/constants/constants';
import * as icons from 'assets';
import { getOrderList } from './OrderRecordService';
import './OrderRecord.scss';
import { ButtonBase } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment'


const { Title, Paragraph, Text } = Typography;

const PAGE_CACHE_KEY = 'OrderRecord';

const OrderRecord = props => {
  const [isUseCache, getPageCacheData, updatePageCacheData] = usePageCache(PAGE_CACHE_KEY, props);
  const [orderStatus, setOrderStatus] = useState(0);
  const [data, setData] = useState();
  const [loadingData, setLoadingData] = useState();
  const [pagination, setPagination] = useState();
  const [scrollTop, setScrollTop] = useState(0);
  const [deliveryDate, setDeliveryDate] = useState(new Date());

  const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
    <div style={{ display: 'flex', gap: 15 }}>
      <ButtonBase className="custom-date-input" onClick={onClick} ref={ref}>
        {!!value ? moment(value).format('DD MMM YYYY') : ''}
        <img src={icons.ic_calendar} alt='' width={24} />
      </ButtonBase>
      <ButtonBase onClick={() => setDeliveryDate(undefined)}>
        {deliveryDate !== undefined && <img src={icons.ic_close} alt='' />}
      </ButtonBase>
    </div>
  ));

  useEffect(() => {
    updatePageCacheData('status', orderStatus);
  }, [orderStatus]);

  useEffect(() => {
    updatePageCacheData('numberOfOrders', data?.length);
  }, [data]);

  const handleListScroll = (scrollTop) => {
    updatePageCacheData('scrollTop', scrollTop)
  };

  useEffect(() => {
    let status = 0;
    let numberOfItems;
    let scrollTop;
    if (isUseCache) {
      let cacheData = getPageCacheData();
      status = cacheData.status;
      numberOfItems = cacheData.numberOfOrders;
      scrollTop = cacheData.scrollTop;
      setOrderStatus(cacheData.status);
    }
    refreshOrderRecords(status, numberOfItems, scrollTop);
  }, []);

  useEffect(() => {
    refreshOrderRecords(orderStatus)
  }, [deliveryDate])

  const fetchData = async (orderStatus, lastItemOrderId, numberOfItems) => {
    try {
      const delivery_date = !!deliveryDate ? moment(deliveryDate).format('YYYY-MM-DD') : undefined
      const res = await getOrderList(getLangCode(props.locale), orderStatus, lastItemOrderId, numberOfItems, delivery_date);
      if (!isEmpty(res.data)) {
        return res.data;
      }
    } catch (e) {
      throw e;
    }
  };

  const refreshOrderRecords = async (orderStatus, numberOfItems, scrollTop = 0) => {
    setLoadingData(true);
    setData(undefined);
    setPagination(undefined);
    let response = await fetchData(orderStatus, null, numberOfItems);
    setData(response.data.orders);
    setPagination(response.pagination);
    setScrollTop(scrollTop);
    setLoadingData(false);
  };

  const loadMoreOrderRecords = async (lastItem) => {
    let lastItemId = lastItem ? lastItem.order_id : undefined;
    let response = await fetchData(orderStatus, lastItemId);
    if (data) {
      setData([...data, ...response.data.orders]);
    } else {
      setData(response.data.orders);
    }
    setPagination(response.pagination);
  };

  const handleOrderStatusChange = (newStatus) => {
    refreshOrderRecords(newStatus);
    setOrderStatus(newStatus);
  };

  const renderDeliveryInfo = item => {
    switch (item.status) {
      case STATUS_PENDING:
        return (
          <InfoGroup
            labelID="IDS_ESTIMATED_DELIVERY"
            className="estimated-delivery">
            {formatDate(item.estimated_delivery, 'DD MMM')}
          </InfoGroup>
        );

      case STATUS_RECEIVED:
        return (
          <InfoGroup
            labelID="IDS_DELIVERY_DATE"
            className="delivery-date">
            <img
              className="icon"
              src={icons.ic_tick}
              alt="icon_tick"
            />
            {formatDate(item.estimated_delivery, 'DD MMM')}
          </InfoGroup>
        );

      case STATUS_SUBMITTED:
        return (
          <InfoGroup
            labelID="IDS_ESTIMATED_DELIVERY"
            className="estimated-delivery">
            {formatDate(item.estimated_delivery, 'DD MMM')}
          </InfoGroup>
        );

      default:
        return;
    }
  };

  const goToOrderDetail = (order) => {
    props.history.push(routes.ORDER_DETAILS.replace(':orderId', order.order_id));
  };

  const renderListItems = (items) => {
    let message;
    if (items && isEmpty(items)) {
      message = (
        <div className="message-container">
          <Text className="message">
            <FormattedMessage id="IDS_NO_ORDER_RECORD_YET" />
          </Text>
        </div>
      );
    }
    return <>
      {message}
      <AppList dataSource={data}
        showLoading={loadingData}
        hasMore={pagination?.hasMore}
        onLoadMore={loadMoreOrderRecords}
        scrollTop={scrollTop}
        onScroll={handleListScroll}
        renderItem={(item) => (
          <Card hoverable onClick={() => goToOrderDetail(item)}>
            <Row>
              <Col span={10}>
                <InfoGroup className="supplier-name" labelID="IDS_SUPPLIER"
                  noColon={true}>{item.name}</InfoGroup>
              </Col>
              <Col span={1} />
              <Col span={9}>{renderDeliveryInfo(item)}</Col>
              <Col span={4}>
                <div className="order-date-status-info-group">
                  <Text className="order-date">
                    {formatDate(item.order_at, 'YYYY / MM / DD')}
                  </Text>
                  <StatusTag status={item.status} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="order-no-receipt-no-info-group">
                  <Paragraph>
                    <FormattedMessage id="IDS_ORDER_NO" />:&nbsp;
                    <Text strong>{item.order_no}</Text>
                    <Divider className="divider" type="vertical" />
                    <FormattedMessage id="IDS_RECEIPT_NO" />:&nbsp;
                    <Text strong>{item.receipt_no}</Text>
                  </Paragraph>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="total-order-number-info">
                  <Tag>
                    <Text>
                      <FormattedMessage
                        id={item.total_order_number > 1 ? 'IDS_ORDERED_ITEMS' : 'IDS_ORDERED_ITEM'}
                        values={{ value: item.total_order_number }}
                      />
                    </Text>
                    {item.products.map((data, index) => {
                      const { code, unit, name, ordered_qty, received_qty } = data;
                      const value = item.status === STATUS_SUBMITTED || item.status === STATUS_PENDING ? ordered_qty : received_qty;

                      return <div className='order-item' key={index}>
                        <div className='metadata'>
                          <div>{code}</div>
                          <span style={{ marginLeft: 8, marginRight: 8 }}>|</span>

                          <div>{unit}</div>
                        </div>
                        <div className='name'>{name}</div>
                        <div className='ordered-or-received-qty'>{value || 0}</div>
                      </div>
                    })}
                  </Tag>
                </div>
              </Col>
            </Row>
          </Card>
        )} />
    </>
  };

  return (
    <div className="order-record-container">
      <Layout>
        <div className="app-scrollable-container">
          <div className="app-content-container">
            <div className="header-group">
              <div className="page-info-container">
                <div className="page-title">
                  <Title level={3}>
                    <FormattedMessage id="IDS_ORDER_RECORD" />
                  </Title>
                </div>
              </div>
              <Row className="status-filter-container">
                <Col span={24}>
                  <Radio.Group buttonStyle="solid" value={orderStatus}
                    onChange={(event) => handleOrderStatusChange(event.target.value)}>
                    <Space size={24}>
                      <Radio.Button value={0}>
                        <FormattedMessage id="IDS_ALL" />
                      </Radio.Button>
                      <Radio.Button value={3}>
                        <FormattedMessage id="IDS_SUBMITTED" />
                      </Radio.Button>
                      <Radio.Button value={1}>
                        <FormattedMessage id="IDS_PROCESSING" />
                      </Radio.Button>
                      <Radio.Button value={2}>
                        <FormattedMessage id="IDS_RECEIVED" />
                      </Radio.Button>
                      <Radio.Button value={4}>
                        <FormattedMessage id="IDS_FAILED" />
                      </Radio.Button>
                    </Space>
                  </Radio.Group>
                </Col>
                <Col span={24}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 12, color: '#4F4E66', marginTop: 12 }}>
                    <span style={{ whiteSpace: 'nowrap', fontWeight: 700, fontSize: 16 }}>
                      <FormattedMessage id="IDS_DELIVERY_DATE" />
                    </span>
                    <DatePicker
                      selected={deliveryDate}
                      onChange={(date) => { setDeliveryDate(date); refreshOrderRecords(orderStatus); }}
                      customInput={<CustomDateInput />}
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => <div className='calendar-header'>
                          <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: 16 }}>
                            <span >{moment(date).format('MMMM')}{' '}{moment(date).year()}</span>
                            <div>
                              <img onClick={decreaseMonth} src={icons.ic_arrow_right} alt='' style={{ transform: 'rotate(180deg)', marginRight: 16, cursor: 'pointer' }} />
                              <img onClick={increaseMonth} src={icons.ic_arrow_right} alt='' style={{ cursor: 'pointer' }} />
                            </div>
                          </div>
                        </div>}
                      showPopperArrow={false}
                      popperClassName='popper-classname'
                      calendarClassName='calendar-classname'
                      dayClassName={(date) => 'day-classname'}
                      calendarStartDay={1}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="body-group">
              {renderListItems(data)}
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default connect(
  (state) => ({
    locale: state.system.locale
  }),
  {})
  (withRouter(OrderRecord));
