import api from "utils/helpers/api";

export const getMonthlyInventoryDetail = (langCode, inventoryId) => {
  return api({
    method: 'GET',
    url: '/api/v1/monthlyInventory/detail',
    params: {
      lang_code: langCode,
      id: inventoryId
    }
  })
};

export const getMonthlyInventoryDetailV2 = (params) => {
  return api({
    method: 'GET',
    url: '/api/v2/monthlyInventory/detail',
    params
  })
};

export const submitMonthInventory = (data) => {
  return api({
    method: 'POST',
    url: '/api/v1/monthlyInventory/submit',
    data: data
  })
};

export const getFilterSearchProduct = (params) => {
  return api({ method: 'get', url: '/api/v1/monthlyInventory/filter/searchProduct', params });
};
export const getFilterCategories = (params) => {
  return api({ method: 'get', url: '/api/v1/monthlyInventory/filter/categoryList', params });
};
export const getFilterSuppliers = (params) => {
  return api({ method: 'get', url: '/api/v1/monthlyInventory/filter/supplierList', params });
};