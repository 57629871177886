import React from 'react';
import { Select } from 'antd';
import * as icons from 'assets';
import './borderlessAppSelect.scss'

const { Option } = Select;

const BorderlessAppSelect = (props) => {
  let { className, value, onChange, selections, optionLabelProp } = props;
  const handleOnChange = (newValue) => {
    if (onChange) {
      onChange(newValue);
    }
  };
  return (
    <div className="app-select-container-borderless" >
      <Select className={className}
        optionLabelProp={optionLabelProp}
        value={value} onChange={handleOnChange}
        bordered={false}
        suffixIcon={<img src={icons.ic_drop_down} alt="" />}
        dropdownClassName="app-select-dropdown">
        {
          selections.map(item => (
            <Option key={item.id} id={item.id} title={optionLabelProp ? item[optionLabelProp] : undefined}>{item.label}</Option>
          ))
        }
      </Select>
    </div>
  )
}

export default BorderlessAppSelect;