import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Modal } from 'antd';
import { withStyles } from '@material-ui/core/styles';
import { Radio } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as icons from 'assets';
import {
  isEmpty,
  removeDuplicateName,
  removeDuplicateCategories,
} from 'utils/helpers/helpers';
import SelectCustom from 'components/select/SelectCustom';
import { BootstrapInput } from 'components/select/BootstrapInput';
import { getFilterSearchProduct, getFilterCategories, getFilterSuppliers } from './InventoryDetailService';
import { getLangCode } from '../../../utils/helpers/helpers';

const CustomRadio = withStyles({
  root: {
    color: '#6461B4',
    '&$checked': { color: '#6461B4' },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
const useStyles = makeStyles({
  noOptions: {
    color: '#4F4E66',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '24px',
  },
});

const FilterModal = (props) => {
  const { locale } = props;
  const intl = useIntl();
  const styles = useStyles();
  const { handleClose, filterValue, handleFilter, inventoryId, openModal } = props;
  const [initialize, setInitalize] = useState(false);
  const [open, setOpen] = useState(false);
  const [allSuppliers, setAllSuppliers] = useState([]);
  const [totalCategories, setTotalCategories] = useState([]);
  const [totalItems, setTotalItems] = useState([]);
  const [updateCategories, setUpdateCategories] = useState(false);
  const [updateSuppliers, setUpdateSuppliers] = useState(false);

  const [itemsSelected, setSelected] = useState(filterValue.suppliers || []);
  const [categorySelected, setCategorySelected] = useState(
    filterValue.categories || []
  );
  const [subItemSelected, setSubItemSelected] = useState(
    filterValue.items || []
  );

  const handleReset = () => {
    setSelected([]);
    setCategorySelected([]);
    setSubItemSelected([]);
  };
  const handleSave = () => {
    if (handleFilter)
      handleFilter({
        suppliers: itemsSelected,
        categories: categorySelected,
        items: subItemSelected,
      });
    handleClose();
  };

  const startAdornment = (
    <img
      src={icons.ic_search_black}
      alt=""
      style={{ margin: '0 8px 0 16px', cursor: 'pointer' }}
    />
  );

  useEffect(() => {
    async function cryingWithThisInitializeLogic() {
      if (!initialize) {
        setInitalize(true);
        let categories = "";
        if (!isEmpty(categorySelected)) {
          categorySelected.forEach((value) => {
            if (categories === "")
              categories = value.id;
            else
              categories = categories + ',' + value.id;
          });
        }
        let suppliers = "";
        if (!isEmpty(itemsSelected)) {
          itemsSelected.forEach((value) => {
            if (suppliers === "")
              suppliers = value.id;
            else
              suppliers = suppliers + ',' + value.id;
          });
        }
        let items = "";
        if (!isEmpty(subItemSelected)) {
          subItemSelected.forEach((value) => {
            if (items === "")
              items = value.id;
            else
              items = items + ',' + value.id;
          });
        }

        const cat_res = await getFilterCategories({
          lang_code: getLangCode(locale),
          supplier_ids: suppliers
        });
        setTotalCategories(cat_res?.data?.data?.categories);

        const sup_res = await getFilterSuppliers({
          lang_code: getLangCode(locale),
          category_ids: categories
        });
        setAllSuppliers(sup_res?.data?.data?.suppliers);

        const item_res = await getFilterSearchProduct({
          lang_code: getLangCode(locale),
          id: inventoryId
        });
        setTotalItems(item_res?.data?.data?.products);
      }
    }
    cryingWithThisInitializeLogic()
  })

  useEffect(() => {
    if (updateCategories) {
      setUpdateCategories(false);
      updateCategoriesFunction();
    }
  }, [updateCategories]);

  useEffect(() => {
    if (updateSuppliers) {
      setUpdateSuppliers(false);
      updateSuppliersFunction();
    }
  }, [updateSuppliers]);

  const updateItemsDropdown = (inputValue) => {
    if (inputValue) {
      setOpen(true);
    } else {
      setOpen(false);
      if (!isEmpty(categorySelected)) {
        setOpen(true);
      }
      if (!isEmpty(itemsSelected)) {
        setOpen(true);
      }
    }
  };

  const updateCategoriesFunction = async () => {
    let categories = "";
    if (!isEmpty(categorySelected)) {
      categorySelected.forEach((value) => {
        if (categories === "")
          categories = value.id;
        else
          categories = categories + ',' + value.id;
      });
    }
    const sup_res = await getFilterSuppliers({
      lang_code: getLangCode(locale),
      category_ids: categories
    });
    setAllSuppliers(sup_res?.data?.data?.suppliers);

    let suppliers = "";
    if (!isEmpty(itemsSelected)) {
      itemsSelected.forEach((value) => {
        if (suppliers === "")
          suppliers = value.id;
        else
          suppliers = suppliers + ',' + value.id;
      });
    }
    const item_res = await getFilterSearchProduct({
      lang_code: getLangCode(locale),
      id: inventoryId
    });
    setTotalItems(item_res?.data?.data?.products);
  }

  const updateSuppliersFunction = async () => {
    let suppliers = "";
    if (!isEmpty(itemsSelected)) {
      itemsSelected.forEach((value) => {
        if (suppliers === "")
          suppliers = value.id;
        else
          suppliers = suppliers + ',' + value.id;
      });
    }
    const cat_res = await getFilterCategories({
      lang_code: getLangCode(locale),
      supplier_ids: suppliers
    });
    setTotalCategories(cat_res?.data?.data?.categories);

    let categories = "";
    if (!isEmpty(categorySelected)) {
      categorySelected.forEach((value) => {
        if (categories === "")
          categories = value.id;
        else
          categories = categories + ',' + value.id;
      });
    }
    const item_res = await getFilterSearchProduct({
      lang_code: getLangCode(locale),
      id: inventoryId
    });
    setTotalItems(item_res?.data?.data?.products);
  }

  return (
    <Modal
      visible={openModal}
      title={null}
      centered
      closeIcon={<img src={icons.ic_close} alt="" />}
      onOk={handleSave}
      onCancel={handleClose}
      className="modal-container"
      width={476}
      footer={null}
    >
      <div className="modal-filter-content">
        <p className="title-filter-text">
          <FormattedMessage id="IDS_SAVE_ORDER_ITEM" />
        </p>
        <p className="title-item">
          <FormattedMessage id="IDS_ITEMS" />
        </p>
        <Autocomplete
          id="combo-box-demo"
          classes={{ noOptions: styles.noOptions }}
          noOptionsText={intl.formatMessage({ id: 'IDS_NO_KEYWORD_MATCH' })}
          options={totalItems}
          getOptionLabel={(option) => option.name}
          style={{ width: '100%' }}
          open={open}
          onClose={() => setOpen(false)}
          onInputChange={async (event, value) => {
            //setInputValue(value);
            updateItemsDropdown(value);
          }}
          renderInput={(params) => {
            const { InputLabelProps, InputProps, ...rest } = params;
            return (
              <BootstrapInput
                {...params.InputProps}
                {...rest}
                placeholder={intl.formatMessage({
                  id: 'IDS_PLACEHOLDER_SEARCH_ITEMS',
                })}
                startAdornment={startAdornment}
                endAdornment={null}
              />
            );
          }}
          renderOption={(option, idx) => (
            <p
              key={idx}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
              onClick={() => {
                // const result = subItemSelected.find(
                //   (v) => v.name === option.name
                // );
                // if (result) {
                //   setSubItemSelected(
                //     subItemSelected.filter((v) => v.name !== result.name)
                //   );
                // } else {
                //   setSubItemSelected([...subItemSelected, option]);
                // }
                setSubItemSelected([option]);
              }}
            >
              <span>{option.name}</span>
              <CustomRadio
                checked={Boolean(
                  subItemSelected.find((v) => v.name === option.name)
                )}
              />
            </p>
          )}
        />

        <div className="tag-group">
          {!isEmpty(subItemSelected) &&
            subItemSelected.map((el) => (
              <span className="tag-item" key={el.name}>
                {el.name}{' '}
                <img
                  src={icons.ic_close}
                  alt=""
                  onClick={() => {
                    setSubItemSelected(
                      subItemSelected.filter((v) => v.name !== el.name)
                    );
                  }}
                />
              </span>
            ))}
        </div>

        <p className="title-item">
          <FormattedMessage id="IDS_CATEGORIES" />
        </p>
        <SelectCustom
          options={totalCategories}
          multiple
          getOptionLabel={(v) => v.name}
          onSelectOption={(value) => {
            const result = categorySelected.find((v) => v.name === value.name);
            if (result) {
              setCategorySelected(
                categorySelected.filter((v) => v.name !== result.name)
              );
            } else {
              setCategorySelected([...categorySelected, value]);
            }
            setUpdateCategories(true);
          }}
          value={categorySelected}
          valueString={intl.formatMessage({ id: 'IDS_CATEGORIES' })}
          iconRight={
            <img
              src={icons.ic_arrow_down}
              alt=""
              style={{ marginRight: 24, cursor: 'pointer' }}
            />
          }
        />

        <div className="tag-group">
          {!isEmpty(categorySelected) &&
            categorySelected.map((el, i) => (
              <span className="tag-item" key={i}>
                {el.name}{' '}
                <img
                  src={icons.ic_close}
                  alt=""
                  onClick={() => {
                    setCategorySelected(
                      categorySelected.filter((v) => v.name !== el.name)
                    );
                    setUpdateCategories(true);
                  }}
                />
              </span>
            ))}
        </div>
        <p className="title-item">
          <FormattedMessage id="IDS_SUPPLIER" />
        </p>
        <SelectCustom
          options={allSuppliers}
          multiple
          getOptionLabel={(v) => v.name}
          onSelectOption={(value) => {
            const result = itemsSelected.find((v) => v.id === value.id);
            if (result) {
              setSelected(itemsSelected.filter((v) => v.id !== result.id));
            } else {
              setSelected([...itemsSelected, value]);
            }
            setUpdateSuppliers(true);
          }}
          value={itemsSelected}
          valueString={intl.formatMessage({ id: 'IDS_SUPPLIER' })}
          iconRight={
            <img
              src={icons.ic_arrow_down}
              alt=""
              style={{ marginRight: 24, cursor: 'pointer' }}
            />
          }
        />

        <div className="tag-group">
          {!isEmpty(itemsSelected) &&
            itemsSelected.map((el, i) => (
              <span className="tag-item" key={el.id}>
                {el.name}{' '}
                <img
                  src={icons.ic_close}
                  alt=""
                  onClick={() => {
                    setSelected(itemsSelected.filter((v) => v.id !== el.id));
                    setUpdateSuppliers(true);
                  }}
                />
              </span>
            ))}
        </div>
        <div className="filter-footer">
          <Button className="outline-btn" onClick={handleReset}>
            <FormattedMessage id="IDS_RESET" />
          </Button>
          <Button className="primary-btn" onClick={handleSave}>
            <FormattedMessage id="IDS_DONE" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default FilterModal;
