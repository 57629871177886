export const enGB = {
  monthsShort: [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ],
  weekdaysShort: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
}
export const zhHK = {
  monthsShort: [
    "01月", "02月", "03月", "04月", "05月", "06月",
    "07月", "08月", "09月", "10月", "11月", "12月"
  ],
  weekdaysShort: '週日_週一_週二_週三_週四_週五_週六'.split('_'),
  longDateFormat: {
    LL: 'MM月DD日',
  },
}
export const zhCN = {
  monthsShort: [
    "01月", "02月", "03月", "04月", "05月", "06月",
    "07月", "08月", "09月", "10月", "11月", "12月"
  ],
  weekdaysShort: '周日_周一_周二_周三_周四_周五_周六'.split('_'),
  longDateFormat: {
    LL: 'MM月DD日',
  },
}