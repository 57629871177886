import React from 'react';
import { Pagination } from 'antd';
import { ReactComponent as IconPrev } from '../../assets/icons/ic_prev.svg';
import { ReactComponent as IconNext } from '../../assets/icons/ic_next.svg';
import './AppPagination.scss';

const AppPagination = ({ totalElements, onChange, page, size }) => {

  const itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return (
        <li className="ant-pagination-prev">
          <button className="ant-pagination-item-link">
            <IconPrev/>
          </button>
        </li>
      )
    }
    if (type === 'next') {
      return (
        <li className="ant-pagination-next">
          <button className="ant-pagination-item-link">
            <IconNext/>
          </button>
        </li>
      )
    }
    return originalElement;
  }

  return (
    <div className="app-pagination">
      <Pagination
        current={page + 1}
        pageSize={size}
        total={totalElements || 0}
        showSizeChanger={false}
        onChange={onChange}
        itemRender={itemRender}
      />
    </div>
  );
};

export default AppPagination;
