import React from "react";
import { Tag } from "antd";
import { STATUS_PENDING, STATUS_RECEIVED, STATUS_DAMAGED, STATUS_SUBMITTED, STATUS_FAILED } from "utils/constants/constants";
import "./StatusTag.scss";
import { FormattedMessage } from 'react-intl';

const statusLabelIds = {
  pending: 'IDS_PENDING',
  received: 'IDS_RECEIVED',
  damaged: 'IDS_DAMAGED',
  processing: 'IDS_PROCESSING',
  submitted: 'IDS_SUBMITTED',
  failed: 'IDS_FAILED'
}

const StatusTag = (props) => {
  return (
    <div className="status-tag-container">
      <Tag className={`status-tag 
                ${{ ...props.className || '' }} 
                ${props.status === STATUS_PENDING ? 'pending' : ''} 
                ${props.status === STATUS_RECEIVED ? 'received' : ''}
                ${props.status === STATUS_DAMAGED ? 'damaged' : ''}
                ${props.status === STATUS_SUBMITTED ? 'submitted' : ''}
                ${props.status === STATUS_FAILED ? 'failed' : ''}`
      }>
        <FormattedMessage id={statusLabelIds[props.status]} />
      </Tag>
    </div>
  );
};

export default StatusTag;
