import React, { useEffect, useRef, useState } from 'react';
// import { getBorrowDetail, updateBorrowing } from './BorrowDetailService';
import { getLedingFormCategoriesDetail } from '../LendingFormService';
import { isEmpty, getLangCode } from 'utils/helpers/helpers';
import Layout from 'components/layout/Layout';
import { Row, Col, Button, Typography, Divider } from 'antd';
// import { ReactComponent as FilterIcon } from 'assets/icons/ic_filter.svg';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RoundImage from 'components/image/RoundImage';
import InfoGroup from 'components/infoGroup/InfoGroup';
import AppTable from 'components/table/AppTable';
import './LendingGoodCategoryDetail.scss';
import queryString from 'query-string';
import NumberEditPopup from 'components/input/NumberEditPopup';
import { routes } from '../../../utils/constants/constants';

const { Text } = Typography;
const itemColumns = (dataTable) => {
  return [
    {
      title: <FormattedMessage id="IDS_ITEMS" />,
      render: (item) => (
        <div className="app-flex-container items-info-cell">
          <RoundImage src={item.image} alt="Item Image" />
          <div>
            <InfoGroup
              label={
                <>
                  <Text>{item.code}
                    <Divider type="vertical" />
                    <span style={{ fontSize: 17, color: 'red', fontWeight: 'normal' }}>
                      <FormattedMessage id="IDS_UNIT" values={{ value: item.unit }} />
                    </span>
                  </Text>
                </>
              }
              noColon={true}
            >
              {item.name}&nbsp;
              <FormattedMessage
                id="IDS_WEIGHT_PER_PACKS"
                values={{ weight: item.pack_weight }}
              />
            </InfoGroup>
          </div>
        </div>
      ),
    },
    {
      title: '',
      align: 'center',
      width: '130px',
      render: (item, actionProviders) => {
        const newItem = dataTable.find((v) => v.id === item.id) || {};
        if (item.edited) {
          return (
            // <div className="borrowed-qty">{item.borrowed_qty}</div>
            <div className="app-button quantity-button borrowed-quantity">
              <NumberEditPopup
                value={newItem.borrowed_qty || 0}
                minValue={0}
                maxValue={99999}
                disableFractional={false}
                onCancel={() =>
                  actionProviders.updateItemActualWeight(
                    item._index,
                    0
                  )
                }
                onPopupCancel={(originValue) =>
                  actionProviders.updateItemActualWeight(
                    item._index,
                    originValue
                  )
                }
                onValueChanged={(newValue) => {
                  actionProviders.updateItemActualWeight(item._index, newValue);
                }}
              >
                <Button>{newItem.borrowed_qty || 0}</Button>
              </NumberEditPopup>
            </div>
          );
        }
        return <div
          className="borrow"
          onClick={() => actionProviders.onChangeStateLending(item._index)}
        >
          <FormattedMessage id='IDS_BORROW' />
        </div>
      },
    }
  ];
};

const LendingGoodCategoryDetail = (props) => {
  const [data, setData] = useState({});
  const [units, setUnits] = useState([]);

  const addedItem = useRef(false)
  const bodySend = useRef([])

  const paramsUrl = queryString.parse(props.location.search);

  const fetchData = async () => {
    try {
      const res = await getLedingFormCategoriesDetail({
        lang_code: getLangCode(props.locale),
        id: props.match.params.id,
        is_favorite_category: paramsUrl.type === 'categories' ? 0 : 1,
        shop_id: paramsUrl.shop_id,
      });

      if (!isEmpty(res.data)) {
        addedItem.current = true;

        const historyData = JSON.parse(localStorage.getItem('lendingData'));
        const historyItems = historyData?.categories?.find(
          (element) => element.name === res.data.data.category.name
        );

        const parseData = historyItems
          ? mergeArrays(historyItems.items, res.data.data.category.items)
          : [];

        const wapperParseData = historyItems
          ? { name: res.data.data.category.name, items: parseData }
          : res.data.data.category;

        let newData = res.data.data;
        newData.categories = [];
        newData.category = wapperParseData;
        newData.categories.push(wapperParseData);
        setData(newData);
        setUnits(res.data.data.units);
        localStorage.setItem('units', JSON.stringify(res.data.data.units));
        bodySend.current = wapperParseData.items;
      }
    } catch (e) { }
  };
  const mergeArrays = (a, b) => {
    var hash = {};
    var ret = [];

    for (var i = 0; i < a.length; i++) {
      var e = a[i].id;
      if (!hash[e]) {
        hash[e] = true;
        ret.push(a[i]);
      }
    }
    for (var i = 0; i < b.length; i++) {
      var e = b[i].id;
      if (!hash[e]) {
        hash[e] = true;
        ret.push(b[i]);
      }
    }

    return ret;
  };

  const goBack = () => {
    props.history.goBack();
  };
  useEffect(() => {
    fetchData();
  }, []);

  const onConfirm = () => {
    let cleanBodySend = bodySend.current.filter((item) => item.borrowed_qty > 0);
    let newBodySend = {
      items: cleanBodySend,
      name: data.category.name,
    };

    if (addedItem.current) {
      let newData = JSON.parse(localStorage.getItem('lendingData'));
      if (newData) {
        let indexOfItemSelected = newData.categories.findIndex(
          (item) => item.name === data.category.name
        );
        if (indexOfItemSelected !== -1) {
          newData.categories.splice(indexOfItemSelected, 1, newBodySend);
        } else {
          newData.categories.push(newBodySend);
        }
        delete newData.category;
        delete newData.units;
        localStorage.setItem('lendingData', JSON.stringify(newData));
      } else {
        let newData = {
          categories: [newBodySend],
        };
        // let saveData = data
        // delete saveData.category
        // delete saveData.units
        localStorage.setItem('lendingData', JSON.stringify(newData));
      }
    } else {
      // let newData = JSON.parse(localStorage.getItem("lendingData"));
      // if (newData) {
      //   let indexOfItemSelected = newData.categories.findIndex(item => item.name === data.category.name)
      //   if (indexOfItemSelected != -1) {
      //     newData.categories.splice(indexOfItemSelected, 1)
      //     localStorage.setItem("lendingData", JSON.stringify(newData));
      //   }
      // }
    }
    props.history.push(routes.LENDING_CONFIRM);
  };
  const onChangeStateLending = (index) => {

    let items = [...data.categories[0].items];
    items[index].edited = true;
    items[index].borrowed_qty = 10;
    items[index].unit_id = units[0].id;
    items[index].unit_name = units[0].name;

    let newData = { ...data };
    newData.categories[0].items = items;
    setData(newData);

    let newbodySend = bodySend.current;
    let newItem = data.categories[0].items[index];
    newItem.borrowed_qty = 10;
    newItem.unit_name = units[0].name;
    newItem.unit_id = units[0].id;

    let indexItem = bodySend.current.findIndex(
      (e) => e.id == data.categories[0].items[index].id
    );
    if (indexItem) {
      newbodySend.splice(indexItem, 1, newItem);
    }

    bodySend.current = newbodySend
  };
  const updateItemActualWeight = (index, newWeight) => {
    addedItem.current = true;
    data.categories[0].items[index].borrowed_qty = newWeight;
    let newData = Object.assign({}, data);
    setData(newData);

    let newbodySend = bodySend.current;
    let newItem = data.categories[0].items[index];
    newItem.borrowed_qty = newWeight;
    let indexItem = bodySend.current.findIndex(
      (e) => e.id == data.categories[0].items[index].id
    );
    if (indexItem) {
      newbodySend.splice(indexItem, 1, newItem);
    }

    bodySend.current = newbodySend
  };
  const updateItemUnit = (index, newUnit) => {
    addedItem.current = true;
    data.categories[0].items[index].unit_id = newUnit;
    data.categories[0].items[index].unit_name = units.find(
      (item) => item.id === newUnit
    ).name;
    let newData = Object.assign({}, data);
    setData(newData);

    let newbodySend = bodySend.current;
    let newItem = data.categories[0].items[index];
    newItem.unit_id = newUnit;
    newItem.unit_name = units.find((item) => item.id === newUnit).name;
    let indexItem = bodySend.current.findIndex(
      (e) => e.id == data.categories[0].items[index].id
    );
    if (indexItem) {
      newbodySend.splice(indexItem, 1, newItem);
    }
    // newbodySend.push(newItem);
    bodySend.current = newbodySend
  };
  const dataTable = !isEmpty(data.categories) ? data.categories[0].items : [];
  // const dataTable = !isEmpty(data.categories) ? data.categories[0].items.filter((v, idx) => idx < 10) : [];

  return (
    <Layout emptyDrawer={true}>
      <div className="lending-form-container">
        <div className="app-content-container content-container table-container">
          <Row className="borrow-detail-header">
            <Col span={24}>
              {/* <div className="app-flex-container height-full flex-va-center">
                <Title level={3}>{data.borrowing?.name}</Title>
              </div> */}
              <div>
                <span className="category-name">
                  {paramsUrl?.category_name}
                </span>
                <span className="sub-category-name"> - <FormattedMessage id="IDS_SELECTED_ITEMS" /></span>
              </div>
            </Col>
          </Row>
          <Row className="lending-good-category-detail-table">
            <Col span={24}>
              <AppTable
                columns={itemColumns(dataTable)}
                // columnDataSource={mapFormData}
                dataSource={dataTable}
                // itemsKey="items"
                // groupKey="name"
                // groupExpandable={createGroupExpandable()}
                // rowExpandable={createRowExpandable()}
                // groupError={(item) => formErrors[item.supplier_id]}
                // itemError={(item) => formErrors[item.supplier_id]}
                actionProviders={{
                  onChangeStateLending: onChangeStateLending,
                  updateItemActualWeight: updateItemActualWeight,
                  updateItemUnit,
                  units
                }}
              />
            </Col>
          </Row>
        </div>
        <div className="action-container app-button">
          <Button className="action-button back-button" onClick={goBack}>
            <FormattedMessage id="IDS_BACK" />
          </Button>
          <Button
            type="primary"
            className="action-button"
            style={{ float: 'right' }}
            onClick={onConfirm}
          >
            <FormattedMessage id="IDS_ADD" />
          </Button>
        </div>
      </div>
    </Layout>
  );
};
export default connect()(withRouter(LendingGoodCategoryDetail));
