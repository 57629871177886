import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography, Divider, Button, Tag } from 'antd';
import CircularProgress from '@material-ui/core/CircularProgress';
import Layout from 'components/layout/Layout';
import RoundImage from 'components/image/RoundImage';
import InfoGroup from 'components/infoGroup/InfoGroup';
import AppTable from 'components/table/AppTable';
import NumberEditPopup from 'components/input/NumberEditPopup';
import { formatDate, getLangCode, isEmpty } from 'utils/helpers/helpers';
import { routes } from 'utils/constants/constants';
import * as icons from 'assets';
import { ReactComponent as FilterIcon } from 'assets/icons/ic_filter.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/ic_calendar.svg';
import FilterModal from '../components/FilterModal';
import AppModal from 'components/modal/AppModal';
import { actionSnackBar } from 'view/system/systemAction';
import { getOrderCategoriesDetail, saveOrder } from '../../orderForm/OrderFormActions';
import './CategoryOrderDetail.scss';
import { getSupplierViewEdit } from './CategoryOrderService';
import store from '../../../configStore'
import BorderlessAppSelect from 'components/select/borderlessAppSelect';
import CalendarModal from 'components/calendar-modal/CalendarModal';
import moment from 'moment';

const { Title, Paragraph } = Typography;

let PAGE_SIZE = 50;
let base_date = moment()

const renderDayColumn = (weekday, date) => (
  <div>
    <div>{formatDate(date, 'DD/MM')}</div>
    <div>
      (<FormattedMessage id={`IDS_${weekday.toUpperCase()}`} />)
    </div>
  </div>
);

const getItemQuantityMinValue = (dayDetail) => {
  if (dayDetail.is_after_three_months) {
    return 0;
  }
  return dayDetail.minimum_range > 0 ? dayDetail.minimum_range : 0;
};

const getItemQuantityMaxValue = (dayDetail) => {
  if (dayDetail.is_after_three_months) {
    return dayDetail.avg_qty + dayDetail.after_three_months_range;
  }
  return dayDetail.maximum_range > 0 ? dayDetail.maximum_range : 999;
};

const transformItemQuantityNewValue = (dayDetail, currentValue, newValue) => {
  if (dayDetail.is_after_three_months) {
    if (newValue < currentValue) {
      if (newValue < dayDetail.avg_qty - dayDetail.after_three_months_range) {
        return 0;
      }
    } else {
      if (newValue < dayDetail.avg_qty - dayDetail.after_three_months_range) {
        return dayDetail.avg_qty - dayDetail.after_three_months_range;
      }
    }
  }
  return newValue;
};

const renderItemQuantity = (item, weekday, actionProvider) => {
  let dayDetail = item.day_detail[weekday];
  let disabled = !dayDetail || !dayDetail.is_available;
  let content;
  let currentOrderQty = actionProvider.getItemCurrentOrderQty(item._group_index, item._index, weekday) || 0;
  let button = <Button disabled={disabled}>{currentOrderQty}</Button>;
  const onReachMaxValue = () => {
    store.dispatch(
      actionSnackBar({
        open: true,
        type: 'warning',
        messageID: 'ID_INPUT_OVER_AVG_QTY_WARNING'
      })
    )
  }
  if (disabled) {
    content = button;
  } else {
    content = (
      <NumberEditPopup
        hideUpdateButton
        onReachMaxValue={onReachMaxValue}
        value={currentOrderQty}
        minValue={getItemQuantityMinValue(dayDetail)}
        maxValue={getItemQuantityMaxValue(dayDetail)}
        autoAdjustValue={dayDetail.is_after_three_months}
        // onCancel={() => {
        //   if (dayDetail.is_after_three_months) {
        //     actionProvider.onItemOrderQtyChanged(item._group_index, item._index, weekday, dayDetail.avg_qty, 0);
        //   } else {
        //     actionProvider.onItemOrderQtyChanged(item._group_index, item._index, weekday, dayDetail.prefill_value);
        //   }
        // }}
        onCancel={() => {
          actionProvider.onItemOrderQtyChanged(item._group_index, item._index, weekday, 0);
        }}
        // onPopupCancel={(originValue) =>
        //   actionProvider.onItemOrderQtyChanged(item._group_index, item._index, weekday, originValue)
        // }
        onValueChanged={(newValue) => {
          newValue = transformItemQuantityNewValue(dayDetail, currentOrderQty, newValue);
          actionProvider.onItemOrderQtyChanged(item._group_index, item._index, weekday, newValue);
        }}
      >
        {button}
      </NumberEditPopup>
    );
  }
  return <div className="app-button quantity-button">{content}</div>;
};

const weekdayColumn = (weekday, date) => ({
  title: renderDayColumn(weekday, date),
  key: weekday,
  align: 'center',
  width: '66px',
  render: (item, actionProvider) => renderItemQuantity(item, weekday, actionProvider)
});

const getInitOrderQty = (dayDetail) => {
  if (dayDetail.order_qty) {
    return dayDetail.order_qty;
  }
  if (dayDetail.is_after_three_months) {
    if (
      dayDetail.prefill_value === 0 ||
      dayDetail.prefill_value === dayDetail.avg_qty - dayDetail.after_three_months_range ||
      dayDetail.prefill_value === dayDetail.avg_qty ||
      dayDetail.prefill_value === dayDetail.avg_qty + dayDetail.after_three_months_range
    ) {
      return dayDetail.prefill_value;
    }
    return 0;
  }
  return dayDetail.prefill_value || 0;
};

const initData = (data, changedItems) => {
  let supplierTotalOrderQty = [];
  let submitData = [];

  let suppliers = data.suppliers.map((supplier) => {
    let submitItems = [];

    let totalOrderQty = 0;
    let items = supplier.items.map((item) => {
      let submitDayDetail = {};
      if (changedItems[item.id]) {
        let changedItem = changedItems[item.id];
        item.day_detail.forEach((dayDetail) => {
          dayDetail.order_qty = changedItem[dayDetail.weekday].order_qty;
        });
      }

      let mapDayDetail = item.day_detail.reduce((result, dayDetail) => {
        result[dayDetail.weekday] = {
          ...dayDetail,
          order_qty: getInitOrderQty(dayDetail)
        };
        submitDayDetail[dayDetail.weekday] = {
          date: dayDetail.date,
          order_qty: result[dayDetail.weekday].order_qty,
          quotation_id: item.quotation_id
        };
        totalOrderQty += result[dayDetail.weekday].order_qty;
        return result;
      }, {});

      submitItems.push({
        product_id: item.id,
        day_detail: submitDayDetail
      });

      return {
        ...item,
        day_detail: mapDayDetail
      };
    });

    supplierTotalOrderQty.push(totalOrderQty);

    submitData.push({
      supplier_id: supplier.supplier_id,
      items: submitItems
    });

    return {
      ...supplier,
      items: items
    };
  });
  return [data, suppliers, submitData, supplierTotalOrderQty];
};
let interval = null;
const CategoryOrderDetail = (props) => {
  const { locale } = props;
  const { id } = props.match.params;
  const paramsUrl = queryString.parse(props.location.search);
  const { formatMessage } = useIntl()

  let [data, setData] = useState({});
  let [loadingData, setLoadingData] = useState(true);
  const [displayPageSize, setDisplayPageSize] = useState(formatMessage({ id: 'IDS_APP_SELECT_DISPLAYING_ITEMS' }, { value: 50 }))
  const [weekdayInfos, setWeekdayInfos] = useState([])
  let [suppliers, setSuppliers] = useState([]);
  let [submitData, setSubmitData] = useState([]);
  let [supplierTotalOrderQty, setSupplierTotalOrderQty] = useState([]);
  let [changedData, setChangedData] = useState({});
  let [filterModalVisible, setFilterModalVisible] = useState(false);
  let [confirmLeaveModalVisible, setConfirmLeaveModalVisible] = useState(false);
  let [confirmSaveModalVisible, setConfirmSaveModalVisible] = useState(false);
  let [saveProgressVisible, setSaveProgressVisible] = useState(false);
  let [filterValue, setFilterValue] = useState({});
  let [currentPage, setCurrentPage] = useState(1);
  let [totalPages, setTotalPages] = useState(0);
  let [changedItems, setChangedItems] = useState({});
  const [calendarOpen, setCalendarOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState(moment().add(2, 'days'));

  const handleResetBaseDate = () => {
    setSelectedDate(moment().add(2, 'days'));
  }
  const toggleOpenCalendar = ()=>setCalendarOpen(cur=>!cur)

  const updateBaseDate = () => {
    if (moment(base_date).isSame(moment(selectedDate).subtract(2, 'days'), 'day')) {
      toggleOpenCalendar()
      return
    }
    base_date = moment(selectedDate).subtract(2, 'days');
    toggleOpenCalendar()
    if (currentPage === 1) {
      refreshData()
    } else {
      setCurrentPage(1)
    }
  }

  const setPageSize = (value) => {
    setDisplayPageSize(value);
    PAGE_SIZE = value;
    if (currentPage === 1) {
      refreshData()
    } else {
      setCurrentPage(1)
    }
  }

  const createGroupExpandable = () => {
    return {
      expandable: (item) => !isSupplierValid(item._index),
      render: (item) => {
        let moa = Number(suppliers[item._index].MOA || 0);
        let totalOrderQty = supplierTotalOrderQty[item._index];
        return (
          <Paragraph>
            <img src={icons.ic_warning_white} alt="" />
            <FormattedMessage
              id="IDS_SHIPPING_WARNING"
              values={{
                value: <b>{totalOrderQty - moa} more items</b>
              }}
            />
          </Paragraph>
        );
      }
    };
  };

  const createRowExpandable = () => {
    if (paramsUrl.showPrice) {
      return {
        expandable: (item) => true,
        render: (item) => (
          <div className="total-cost-container">
            <Tag>
              <FormattedMessage
                id="IDS_TOTAL_COST_PER_PACKS"
                values={{
                  currency: item.currency,
                  cost: item.cost
                }}
              />
            </Tag>
          </div>
        )
      };
    }
  };

  const fetchData = async (categoryId, categoryType, page, filterValue) => {
    try {
      let res = {};
      if (!isEmpty(paramsUrl.id)) {
        res = await getSupplierViewEdit({
          lang_code: getLangCode(locale),
          id: categoryId,
          page: page || 1,
          number_of_items: PAGE_SIZE
        });
      } else {
        res = await getOrderCategoriesDetail({
          lang_code: getLangCode(locale),
          is_favorite_category: categoryType === 'categories' ? 0 : 1,
          id: categoryId,
          page: page || 1,
          number_of_items: PAGE_SIZE,
          filter_product_names:
            filterValue.productNames && filterValue.productNames.length > 0
              ? JSON.stringify(filterValue.productNames)
              : undefined,
          filter_supplier_ids:
            filterValue.supplierIds && filterValue.supplierIds.length > 0
              ? filterValue.supplierIds.join(',')
              : undefined,
          filter_is_filled: filterValue.isFilled,
          base_date: moment(base_date).format('DD-MM-YYYY')
        });
      }
      if (!isEmpty(res?.data.data)) {
        return res?.data.data;
      }
    } catch (e) {
      throw e;
    }
  };

  const refreshData = async () => {
    setLoadingData(true);
    let response = await fetchData(id, paramsUrl.type, currentPage, filterValue);
    setWeekdayInfos(response.weekdays || []);
    let [newData, newSuppliers, newSubmitData, newSupplierTotalOrderQty] = initData(response, changedItems);
    setTotalPages(response.page?.total);
    setData(newData);
    setSuppliers(newSuppliers);
    // setOriginSuppliers(newSuppliers);
    setSubmitData(newSubmitData);
    setSupplierTotalOrderQty(newSupplierTotalOrderQty);
    setLoadingData(false);
  };

  useEffect(() => {
    if (!isEmpty(suppliers) && !isEmpty(paramsUrl.id)) {
      interval = setInterval(() => {
        const el = document.getElementById(paramsUrl.id);
        if (el) {
          // el.scrollIntoView({ behavior: "smooth" });
          clearInterval(interval);
        }
      }, 100);
    }
  }, [suppliers]);

  useEffect(() => {
    return () => {
      if (interval) clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    refreshData();
  }, [currentPage, filterValue]);

  const goBack = () => {
    props.history.goBack();
  };

  const prepareGoBack = () => {
    if (isEmpty(changedData)) {
      goBack();
    } else {
      setConfirmLeaveModalVisible(true);
    }
  };

  const isSupplierValid = (supplierIndex) => {
    return true; //Number(suppliers[supplierIndex].MOA || 0) >= (supplierTotalOrderQty[supplierIndex] || 0);
  };

  const formHasError = () => {
    for (let i = 0; i < suppliers.length; i++) {
      if (!isSupplierValid(i)) {
        return true;
      }
    }
    return false;
  };

  const prepareSubmitForm = () => {
    if (!formHasError()) {
      setConfirmSaveModalVisible(true);
    }
  };

  const submitForm = async () => {
    setSaveProgressVisible(true);

    let dateMap = {};

    Object.keys(changedItems).map((productId) => {
      const dayDetail = changedItems[productId];
      Object.keys(dayDetail).map((weekday) => {
        let date;
        if (!dateMap[weekday]) {
          dateMap[weekday] = {
            date: dayDetail[weekday].date,
            items: []
          };
        }
        date = dateMap[weekday];
        date.items.push({
          product_id: productId,
          order_qty: dayDetail[weekday].order_qty || 0,
          quotation_id: dayDetail[weekday].quotation_id
        });
      });
    });

    let convertedSubmitData = {
      lang_code: getLangCode(locale),
      dates: Object.keys(dateMap).map((weekday) => dateMap[weekday])
    };

    try {
      const { data } = await saveOrder(convertedSubmitData);
      if (data.result.status === 200) {
        props.actionSnackBar({
          open: true,
          type: 'success',
          messageID: data.result.message
        });
        props.history.push(routes.ORDER_FORM);
      } else {
        props.actionSnackBar({
          open: true,
          type: 'warning',
          message: data.result.message
        });
        setSaveProgressVisible(false)
        setConfirmSaveModalVisible(false)
        if (data.result.status === 9) {
          props.history.push(routes.RECEIVED_DELIVERY);
        }
      }
    } catch (error) { }
    return false;
  };

  const getItemCurrentOrderQty = (supplierIndex, itemIndex, weekday) => {
    return submitData[supplierIndex]?.items[itemIndex]?.day_detail[weekday]?.order_qty;
  };

  const onItemOrderQtyChanged = (supplierIndex, itemIndex, weekday, newOrderQty) => {
    let newSubmitData = [...submitData];
    let item = newSubmitData[supplierIndex]?.items[itemIndex];
    let dayDetail = item?.day_detail[weekday];
    if (dayDetail) {
      updateSupplierTotalOrderQty(supplierIndex, dayDetail.order_qty, newOrderQty);
      updateChangedItems(supplierIndex, itemIndex, weekday, newOrderQty);
      dayDetail.order_qty = newOrderQty;
      setSubmitData(newSubmitData);
      setChangedItems({
        ...changedItems,
        [item.product_id]: item.day_detail
      });
    }
    return true;
  };

  const updateChangedItems = (supplierIndex, itemIndex, weekday, newOrderQty) => {
    let newChangedData = { ...changedData };
    let originOrderQty = suppliers[supplierIndex].items[itemIndex].day_detail[weekday].order_qty;

    if (newOrderQty === originOrderQty) {
      if (newChangedData[supplierIndex]) {
        let changedSupplier = newChangedData[supplierIndex];

        if (changedSupplier[itemIndex]) {
          let changedItem = changedSupplier[itemIndex];

          if (changedItem[weekday]) {
            delete changedItem[weekday];

            if (isEmpty(changedItem)) {
              delete changedSupplier[itemIndex];

              if (isEmpty(changedSupplier)) {
                delete newChangedData[supplierIndex];
              }
            }
          }
        }
      }
    } else {
      if (!newChangedData[supplierIndex]) {
        newChangedData[supplierIndex] = {};
      }
      let changedSupplier = newChangedData[supplierIndex];

      if (!changedSupplier[itemIndex]) {
        changedSupplier[itemIndex] = {};
      }

      changedSupplier[itemIndex][weekday] = newOrderQty;
    }
    setChangedData(newChangedData);
  };

  const updateSupplierTotalOrderQty = (supplierIndex, oldOrderQty, newOrderQty) => {
    let newSupplierTotalOrderQty = [...supplierTotalOrderQty];
    newSupplierTotalOrderQty[supplierIndex] = newSupplierTotalOrderQty[supplierIndex] - oldOrderQty + newOrderQty;
    setSupplierTotalOrderQty(newSupplierTotalOrderQty);
  };

  const handleFilter = (filterValue) => {
    setFilterValue(filterValue);
  };

  const itemsColumn = {
    title: () => <div className='items-columns-container'>
      <FormattedMessage id="IDS_ITEMS" />
      <div style={{ background: '#BDBDBD', borderRadius: '50%', height: 4, width: 4 }} />
      <BorderlessAppSelect optionLabelProp='title'
        selections={[{ id: 50, label: formatMessage({ id: 'IDS_APP_SELECT_ITEMS' }, { value: 50 }), title: formatMessage({ id: 'IDS_APP_SELECT_DISPLAYING_ITEMS' }, { value: 50 }) }, { id: 100, label: formatMessage({ id: 'IDS_APP_SELECT_ITEMS' }, { value: 100 }), title: formatMessage({ id: 'IDS_APP_SELECT_DISPLAYING_ITEMS' }, { value: 100 }) }]}
        value={displayPageSize}
        onChange={setPageSize} />
    </div >,
    render: (item) => (
      <div className="app-flex-container items-info-cell">
        <RoundImage src={item.image} alt="Item Image" />
        <InfoGroup
          label={
            <>
              {item.code}
              <Divider type="vertical" />
              <span style={{ fontSize: 17, color: 'red' }}>
                <FormattedMessage id="IDS_UNIT" values={{ value: item.unit }} />
              </span>
            </>
          }
          noColon={true}
        >
          {item.name} {item.pack_weight}
        </InfoGroup>
      </div>
    )
  }

  const tableColumns = [itemsColumn, ...weekdayInfos.map(weekdayInfo => weekdayColumn(weekdayInfo.weekday, weekdayInfo.date))]

  return (
    <div className="category-order-detail-container">
      <Layout>
        <div className="app-scrollable-container">
          <div className="app-content-container">
            <div className="header-group">
              <div className="page-info-container app-button">
                <div className="page-title">
                  <Title level={3}>{data.category?.name}</Title>
                </div>
                {data?.allow_order_period === 1 && <Button onClick={toggleOpenCalendar}
                  className='calendar-button'
                  disabled={loadingData}
                  icon={<CalendarIcon />} />}
                <Button
                  disabled={loadingData}
                  className={`${!isEmpty(filterValue.productNames) ||
                    !isEmpty(filterValue.supplierIds) ||
                    !isEmpty(filterValue.isFilled)
                    ? 'active-btn'
                    : ''
                    }`}
                  icon={<FilterIcon />}
                  onClick={() => setFilterModalVisible(true)}
                >
                  <FormattedMessage id="IDS_FILTER" />
                </Button>
              </div>
            </div>
            <div className="body-group">
              <AppTable
                columns={tableColumns}
                dataSource={suppliers}
                showLoading={loadingData}
                itemsKey="items"
                groupKey="supplier_name"
                groupId="supplier_id"
                groupExpandable={createGroupExpandable()}
                rowExpandable={createRowExpandable()}
                groupError={(item) => !isSupplierValid(item._index)}
                itemError={(item) => !isSupplierValid(item._group_index)}
                actionProviders={{
                  onItemOrderQtyChanged: onItemOrderQtyChanged,
                  getItemCurrentOrderQty: getItemCurrentOrderQty
                }}
                pagination={{
                  page: currentPage - 1,
                  size: PAGE_SIZE,
                  totalItems: totalPages * PAGE_SIZE
                }}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="footer-group app-button category-order-detail-footer-group">
              <Button className="back-button" onClick={prepareGoBack}>
                <FormattedMessage id="IDS_BACK" />
              </Button>
              <Button type="primary" className="save-button" onClick={prepareSubmitForm}>
                <FormattedMessage id="IDS_SAVE" />
              </Button>
            </div>
          </div>
        </div>
        <FilterModal
          visible={filterModalVisible}
          handleClose={() => setFilterModalVisible(false)}
          categoryId={id}
          isFavCategory={paramsUrl.type === 'categories' ? 0 : 1}
          handleFilter={handleFilter}
        />
        <AppModal
          visible={confirmLeaveModalVisible}
          titleID="IDS_LEAVE_THIS_PAGE"
          okTextID="IDS_LEAVE"
          onOk={goBack}
          cancelTextID="IDS_STAY"
          onVisibleChange={(visible) => setConfirmLeaveModalVisible(visible)}
        >
          <FormattedMessage id="IDS_LEAVE_DESCRIPTION" />
        </AppModal>
        <AppModal
          visible={confirmSaveModalVisible}
          titleID="IDS_SAVE_ORDER_ITEM"
          okTextID="IDS_SAVE"
          onOk={submitForm}
          onVisibleChange={(visible) => setConfirmSaveModalVisible(visible)}
          shouldDisableAllButton={saveProgressVisible}
          maskClosable={false}
        >
          <FormattedMessage id="IDS_ORDER_ITEM_DESCRIPTION" />
          {saveProgressVisible && (
            <div className="modal-progress-container">
              <CircularProgress />
            </div>
          )}
        </AppModal>
        {calendarOpen && <CalendarModal
          onReset={handleResetBaseDate}
          handleClose={updateBaseDate}
          startDate={selectedDate}
          setStartDate={setSelectedDate}
          calendarProp={{
            dateRange: [moment().add(2, 'days'), moment().add(8, 'days')]
          }}
          okText={<FormattedMessage id='IDS_COMFIRM' />}
        />}
      </Layout>
    </div>
  );
};

export default connect(
  (state) => ({
    locale: state.system.locale,
    account: state.system.account
  }),
  { actionSnackBar }
)(withRouter(CategoryOrderDetail));
