import api from 'utils/helpers/api';

export const getSupplierViewEdit = (params) => {
  return api({
    method: 'GET',
    url: '/api/v1/order/today/supplierViewEdit',
    params,
  });
};

export const getCategorySuppliers = (params) => {
  return api({
    method: 'GET',
    url: '/api/v1/order/category/supplierList',
    params,
  });
};

export const getCategoryNames = (params) => {
  return api({
    method: 'GET',
    url: '/api/v1/order/category/searchProduct',
    params,
  });
};