const config = () => {
  const env = process.env.BUILD_MODE || 'local';
  let BASE_URL = 'https://toa-oms-dev.legato.co';
  // ENV DEV
  if (env === 'dev') {
    BASE_URL = 'https://toa-oms-dev.legato.co';
  } else if (env === 'uat') {
    // UAT
    BASE_URL = 'https://cms-uat.toaoms.com.hk/';
  } else if (env === 'prod') {
    // PROD
    BASE_URL = 'https://cms-prd.toaoms.com.hk';
  }
  return { BASE_URL };
};
const serverConfig = config();

export default serverConfig;
