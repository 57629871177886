import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'antd';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { isEmpty, isFunction, isIPad, getLangCode, formatDate } from 'utils/helpers/helpers';
import CircularProgress from '@material-ui/core/CircularProgress';
import './AppTable.scss';
import AppPagination from '../pagination/AppPagination';
import AppPaginationInventory from 'components/pagination/AppPaginationInventory';

const isIPadDevice = isIPad();

const initTableColumns = (columns) => {
  columns = columns || [];
  let newTableColumns = [];
  columns.forEach((column) => {
    let tableColumn = Object.assign({}, column);
    if (column.width) {
      tableColumn['width'] = column.width;
    } else {
      tableColumn['flex'] = column.colSpan || 1;
    }
    newTableColumns.push(tableColumn);
  });
  return newTableColumns;
};

const initTableData = (dataSource, itemsKey) => {
  dataSource = dataSource || [];
  let newTableData = [];
  dataSource.forEach((data, index) => {
    let item = {
      ...data,
      _is_group: false,
      _index: index
    };
    newTableData.push(item);
    let childItems = [];
    if (itemsKey) {
      item._is_group = true;
      let items = data[itemsKey];
      if (items) {
        items.forEach((item, itemIndex) => {
          let childItem = {
            ...item,
            _is_group: false,
            _index: itemIndex,
            _group_index: index
          };
          childItems.push(childItem);
        });
      }
      item.items = childItems;
    }
  });
  return newTableData;
};

const AppTable = (props) => {
  let {
    dataSource,
    showLoading,
    columns,
    groupFilter,
    itemFilter,
    itemsKey,
    groupKey,
    groupId,
    noStickyGroup,
    rowExpandable,
    groupExpandable,
    groupExtendable,
    groupError,
    itemError,
    actionProviders,
    renderBottom,
    pagination,
    onPageChange,
    inInventoryPage = false
  } = props;

  actionProviders = actionProviders || {};

  let [tableHasScroll, setTableHasScroll] = useState(false);
  let [tableColumns, setTableColumns] = useState([]);
  let [tableData, setTableData] = useState([]);
  let [originTableData, setOriginTableData] = useState([]);

  const intl = useIntl();

  let tableBodyRef = React.createRef();
  let setTableBodyRef = useCallback((ref) => {
    tableBodyRef.current = ref;
    if (tableBodyRef.current) {
      setTableHasScroll(tableBodyRef.current.scrollHeight > tableBodyRef.current.clientHeight);
    }
  }, []);

  const isRowExpandableDisabled = () => {
    return isEmpty(rowExpandable);
  };

  const isGroupExpandableDisabled = () => {
    return isEmpty(groupExpandable);
  };

  const isGroupExtendableDisabled = () => {
    return isEmpty(groupExtendable);
  };

  useEffect(() => {
    setTableColumns(initTableColumns(columns));
  }, [columns]);

  useEffect(() => {
    const tableData = initTableData(dataSource, itemsKey);
    setTableData(tableData);
    setOriginTableData(tableData);
  }, [dataSource]);

  useEffect(() => {
    if (tableBodyRef?.current) {
      setTableHasScroll(tableBodyRef.current.scrollHeight > tableBodyRef.current.clientHeight);
    }
  }, [tableData]);

  useEffect(() => {
    if (!groupFilter && !itemFilter) {
      return;
    }
    let newTableData = [...originTableData];
    if (groupFilter) {
      newTableData = newTableData.filter((item) => groupFilter(item));
    }
    if (itemFilter) {
      for (let i = newTableData.length - 1; i >= 0; i--) {
        const item = { ...newTableData[i] };
        if (item._is_group) {
          item.items = item.items.filter((childItem) => itemFilter(childItem));
          newTableData.splice(i, 1, item);
        } else if (!itemFilter(item)) {
          newTableData.splice(i, 1);
        }
      }
    }
    setTableData(newTableData);
  }, [groupFilter, itemFilter]);

  const renderTableRow = (className, renderCells = () => {}) => {
    return <Row className={className}>{renderCells()}</Row>;
  };

  const renderTableCells = (className, item, contentRender = (item, column, colIndex) => { }) => {
    return tableColumns.map((column, colIndex) => {
      return (
        <Col
          key={`col-${colIndex}`}
          className={className}
          flex={column.flex}
          style={{
            width: column.width,
            minWidth: column.minWidth,
            justifyContent: column.align,
            textAlign: column.align
          }}
        >
          {contentRender(item, column, colIndex)}
        </Col>
      );
    });
  };

  const renderTableGroupItemContentCells = (item) => {
    let result = item[groupKey];
    if (groupKey === 'supplier_name') {
      result = <p dangerouslySetInnerHTML={{ __html: item[groupKey] }} />
    }
    return (
      <Col className="group-item-content-cell" id={item[groupId]} span={24}>
        {result}
      </Col>
    );
  };

  const renderTableGroupItemCells = (item) => {
    let isGroupExpandable = !isGroupExpandableDisabled() && isExpandable(item, groupExpandable);
    let isGroupExtendable = !isGroupExtendableDisabled() && isExtendable(item, groupExtendable);
    return (
      <Col span={24}>
        <div className={`group-item-head-wrapper ${isGroupExtendable ? 'half-border-round' : 'full-border-round'}`}>
          {renderTableRow('group-item-content-row', () => renderTableGroupItemContentCells(item))}
          {isGroupExpandable &&
            renderTableRow('group-item-expand-row', () =>
              renderTableExpandCell('group-item-expand-cell', item, groupExpandable)
            )}
        </div>
        {isGroupExtendable && (
          <div className="group-item-body-wrapper">
            {renderTableRow('group-item-extend-row', () =>
              renderTableExpandCell('group-item-extend-cell', item, groupExtendable)
            )}
          </div>
        )}
      </Col>
    );
  };

  const isExpandable = (item, config) => {
    return config && config.expandable && config.expandable(item) && config.render;
  };

  const isExtendable = (item, config) => {
    return config && config.extendable && config.extendable(item) && config.render;
  };

  const isGroupError = (item) => {
    return groupError ? groupError(item) : false;
  };

  const isItemError = (item) => {
    return itemError ? itemError(item) : false;
  };

  const renderTableItemContentCells = (item) => {
    return renderTableCells('item-content-cell', item, (item, column, colIndex) => {
      return column.render ? column.render(column.dataIndex ? item[column.dataIndex] : item, actionProviders) : '';
    });
  };

  const renderTableExpandCell = (className, item, config) => {
    return (
      <Col className={className} span={24}>
        {config.render(item, actionProviders)}
      </Col>
    );
  };

  const renderTableItemCells = (item) => {
    if (item._is_group) {
      // if (!!item.pre_process_date) {
      //   const name = item.supplier_name;
      //   const newMessage = intl.formatMessage({ id: "IDS_PRE_PROCESS_DATE" }, { pre_process_date: moment(item.pre_process_date, 'YYYY-MM-DD').format('D/MM'), delivery_date: moment(item.delivery_date, 'YYYY-MM-DD').format('D/MM') })
      //   const re = new RegExp(newMessage, 'g');
      //   if (!name.match(re)) {
      //     item.supplier_name = item.supplier_name + ' ' + newMessage;
      //   }
      // }
      if (item.pre_process_list && item.pre_process_list.length !== 0) {
        const name = item.supplier_name;
        let newMessage = '';

        const sortedPreProcessList = item.pre_process_list
          .slice()
          .sort((a, b) => (moment(a.pre_process_date).isBefore(b.pre_process_date) ? -1 : 1));
        // const listHaveSamePreProcessDate = sortedPreProcessList.some((item, index, currentArray) =>
        //   index === 0 ? false : item.pre_process_date === currentArray[index - 1].pre_process_date
        // );
        const langCode = getLangCode();
        if (langCode !== 1) {
          // 2 – Traditional Chinese; 3 – Simplified Chinese
          const ORDER_FROM = langCode === 2 ? ' 訂貨於' : '订货必须于';
          const DELIVER_ON = langCode === 2 ? '截單結算' : '截单';

          const result = {};
          for (const item of sortedPreProcessList) {
            const preProcessDate = item.pre_process_date;
            if (!result[preProcessDate]) {
              result[preProcessDate] = [];
            }
            result[preProcessDate].push(item);
          }
          const subList = Object.values(result);
          subList.forEach((list, index) => {
            if (list.length === 1) {
              const { pre_process_date, delivery_date } = list[0];
              newMessage += `${formatDate(delivery_date, 'DD MMM')} ${ORDER_FROM}${formatDate(
                pre_process_date,
                'DD MMM'
              )}${DELIVER_ON}`;
            } else {
              newMessage += list.reduce((accumulator, currentValue, idx) => {
                const { pre_process_date, delivery_date } = currentValue;
                let result = formatDate(delivery_date, 'DD MMM');
                if (idx !== list.length - 1) {
                  result += ', ';
                } else {
                  result += ` ${ORDER_FROM}${formatDate(pre_process_date, 'DD MMM')}${DELIVER_ON}`;
                }
                return accumulator + result;
              }, '');
            }
            if (index !== subList.length - 1) {
              newMessage += ', ';
            }
          });
        } else {
          const preProcessmessageList = item.pre_process_list.map(({ pre_process_date, delivery_date }) =>
            intl.formatMessage(
              { id: 'IDS_PRE_PROCESS_DATE' },
              {
                pre_process_date: moment(pre_process_date, 'YYYY-MM-DD').format('D/MM'),
                delivery_date: moment(delivery_date, 'YYYY-MM-DD').format('D/MM')
              }
            )
          );
          newMessage = preProcessmessageList.reduce(
            (accumulator, currentValue, index) => accumulator + ', ' + currentValue
          );
        }

        const re = new RegExp(newMessage, 'g');
        if (!name.match(re)) {
          item.supplier_name = item.supplier_name + `<span style="color: #FFE900"> (${newMessage})</span>`;
        }
      }
      return renderTableGroupItemCells(item);
    }
    if (!isRowExpandableDisabled() && isExpandable(item, rowExpandable)) {
      return (
        <Col span={24}>
          {renderTableRow('item-content-row', () => renderTableItemContentCells(item))}
          {renderTableRow('item-expand-row', () => renderTableExpandCell('item-expand-cell', item, rowExpandable))}
        </Col>
      );
    }
    return renderTableItemContentCells(item);
  };

  const renderTableHeaderRow = () => {
    return renderTableRow('header-row', () =>
      renderTableCells('column-cell', null, (item, column) => {
        if (isFunction(column.title)) {
          return column.title(actionProviders);
        }
        return column.title || column.dataIndex;
      })
    );
  };

  const renderTableGroupItemRow = (groupItem) => {
    let itemRows = groupItem.items.map((item, index) => {
      return (
        <Row
          key={`row-${groupItem._group_index}-${index}`}
          className={`item-row ${isItemError(item) ? 'item-row-error' : ''}`}
        >
          {renderTableItemCells(item)}
        </Row>
      );
    });
    return (
      <>
        <Row
          className={`group-item-row ${isGroupError(groupItem) ? 'group-item-row-error' : ''} ${noStickyGroup ? '' : 'group-item-sticky'
            }`}
        >
          {renderTableItemCells(groupItem)}
        </Row>
        {itemRows}
      </>
    );
  };

  const renderPagination = () => {
    if (props.pagination && props.pagination.totalItems / props.pagination.size > 1) {
      return inInventoryPage ? (
        <AppPaginationInventory
          page={pagination.page}
          size={pagination.size}
          totalElements={pagination.totalItems}
          onChange={props.onPageChange}
        />
      ) : (
        <AppPagination
          page={pagination.page}
          size={pagination.size}
          totalElements={pagination.totalItems}
          onChange={props.onPageChange}
        />
      );
    }
  };

  const renderTableBody = () => {
    if (showLoading) {
      return (
        <div className="table-progress-container">
          <CircularProgress />
        </div>
      );
    }
    return (
      <div
        ref={setTableBodyRef}
        key={pagination?.page}
        className={`table-body ${tableHasScroll ? 'content-scrollable' : ''}`}
      >
        {renderTableItemRows()}
        {renderPagination()}
        {renderBottom && renderBottom()}
      </div>
    );
  };

  const renderTableItemRows = () => {
    return tableData.map((item, index) => {
      if (item._is_group) {
        return (
          <div key={`group-${item._index}`} className="group-item-container">
            {renderTableGroupItemRow(item)}
          </div>
        );
      } else {
        return (
          <Row key={`row-${index}`} className={`item-row ${isItemError(item) ? 'item-row-error' : ''}`}>
            {renderTableItemCells(item)}
          </Row>
        );
      }
    });
  };

  return (
    <div className={`app-table ${isIPadDevice ? 'ipad-device' : ''}`}>
      <div className="table-header">{renderTableHeaderRow()}</div>
      {renderTableBody()}
    </div>
  );
};

export default AppTable;
